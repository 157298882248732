<template>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
                            <form @submit.prevent="checkForm" id="addTiers"  autocomplete="off">
                                <div class="row">
                                    <div class="col-12" v-if="form_message !== ''">
                                        <ErrorAlert :messageI18n="form_message" />
                                    </div>

                                    <div class="col-12">
                                        <WarnAlert v-if="tier_already_exists" messageI18n="tiers.already_exist" />
                                    </div>

                                    <div v-if="pappers_key" class="col-12 mb-2">
                                        <label class="col-form-label">{{ $t("tiers.recherche_pappers") }}</label>
                                        <e-select
                                            track-by="id"
                                            label="label"
                                            v-model="searchValue"
                                            :options="pappers_result"
                                            :placeholder="$t('global.rechercher')"
                                            :loading="loading_pappers"
                                            :sortable="false"
                                            :internal-search="false"
                                            preserve-search
                                            reset-after
                                            @search-change="onSearch"
                                            @select="searchPappers"
                                            ref="input"
                                        >
                                            <template slot="option" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
                                            <template slot="singleLabel" slot-scope="{ option }">{{ option.tiers_rs }} ({{ option.tiers_address1 }} {{ option.tiers_postalcode }} {{ option.tiers_town }})</template>
                                            <template slot="noOptions">{{ $t('global.list_empty') }}</template>
                                        </e-select>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_rs" class="col-form-label">{{ $t("tiers.rs") }}</label>
                                            <input type="text" id="tiers_rs" class="form-control" v-model="form.tiers_rs" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur rs') > -1 }">
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_civility" class="col-form-label">{{ $t("tiers.civility") }} *</label>
                                            <e-select
                                                id="tiers_civility"
                                                v-model="form.tiers_civility"
                                                :options="civilities"
                                                :allow-empty="false"
                                                :show-labels="false"
                                                :class="{ 'is-invalid': error && error.indexOf('Erreur civilité') > -1 }"
                                            />
                                            
                                        </div>
                                        <div class="row">
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="tiers_firstname" class="col-form-label">{{ $t("tiers.firstname") }}</label>
                                                    <input type="text" id="tiers_firstname" class="form-control" v-model="form.tiers_firstname" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur prénom') > -1 }">
                                                </div>
                                            </div>
                                            <div class="col-6">
                                                <div class="form-group">
                                                    <label for="tiers_lastname" class="col-form-label">{{ $t("tiers.lastname") }} *</label>
                                                    <input type="text" id="tiers_lastname" class="form-control" v-model="form.tiers_lastname" @blur="checkIdenticalTierRs" required  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur nom') > -1 }">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-12" v-if="mapbox">
                                        <e-address 
                                            :address.sync="form.tiers_address1"
                                            :postalCode.sync="form.tiers_postalcode"
                                            :city.sync="form.tiers_town"
                                            :country.sync="form.tiers_country"
                                        />
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_address1" class="col-form-label">{{ $t("tiers.address1") }}</label>
                                            <input type="text" id="tiers_address1" class="form-control" v-model="form.tiers_address1" required  autocomplete="chrome-off">
                                            
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_address2" class="col-form-label">{{ $t("tiers.address2") }}</label>
                                            <input type="text" id="tiers_address2" class="form-control" v-model="form.tiers_address2" required  autocomplete="chrome-off">
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="form-group">
                                            <label for="tiers_postalcode" class="col-form-label">{{ $t("tiers.postalcode") }}</label>
                                            <input type="text" id="tiers_postalcode" class="form-control" v-if="form.tiers_postalcode" v-model="form.tiers_postalcode" required  autocomplete="chrome-off" @click="resetPostalcode">
                                            <SearchInputCommune v-if="isOnline && !form.tiers_postalcode" :town="0" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
                                        </div>
                                    </div>
                                    <div class="col-8">
                                        <div class="form-group">
                                            <label for="tiers_town" class="col-form-label">{{ $t("tiers.town") }}</label>
                                            <input type="text" id="tiers_town" class="form-control" v-if="form.tiers_town" v-model="form.tiers_town" required autocomplete="chrome-off" @click="resetTown">
                                            <SearchInputCommune v-if="isOnline && !form.tiers_town" :town="1" :tiers_postalcode.sync="form.tiers_postalcode" :tiers_town.sync="form.tiers_town"/>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_country" class="col-form-label">{{ $t("tiers.country") }} *</label>
                                            <CountryInput v-model="form.tiers_country"></CountryInput>
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_mail" class="col-form-label">{{ $t("tiers.email") }}</label>
                                            <input type="email" id="tiers_mail" class="form-control" v-model="form.tiers_mail"  autocomplete="chrome-off" :class="{ 'is-invalid': error && error.indexOf('Erreur mail') > -1 }">
                                        </div>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <label class="col-form-label">{{ $t("tiers.phone") }}</label>
                                    </div>
                                    <div class="col-12 phone mb-3" v-for="(phone, key_phone) in form.phones" v-bind:key="key_phone">
                                        <div class="row no-gutters">
                                            <div class="col-auto pr-2">
                                                <phoneInput v-model="form.phones[key_phone]"></phoneInput>
                                            </div>
                                            <div class="col pr-2">
                                                <div class="input-group">
                                                    <div class="input-group-prepend">
                                                        <b-form-input type="text" class="form-control" style="width: 5em;" v-model="phone.phone_indicatif"/>
                                                    </div>
                                                    <input type="text" class="form-control" v-model="phone.phone_numero" required autocomplete="chrome-off">
                                                </div>
                                            </div>
                                            <div class="col-3 pr-2">
                                                <e-select
                                                    id="phone_type"
                                                    v-model="phone.phone_type"
                                                    :options="[
                                                        $t('tiers.phone_type.fixe'),
                                                        $t('tiers.phone_type.portable')
                                                    ]"
                                                    :allow-empty="false"
                                                    :show-labels="false"
                                                />
                                            </div>
                                            <div class="col-3">
                                                <div class="input-group">
                                                    <input type="text" class="form-control" v-model="phone.phone_libelle" :placeholder="$t('tiers.libelle')">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 text-right">
                                        <a href="#" @click.prevent="addPhoneNumber()">{{ $t('global.ajouter_numero_telephone') }} <font-awesome-icon :icon="['fal', 'phone-plus']"/></a>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <textarea class="form-control" id="logiciel_comptable" :placeholder="$t('tiers.logiciel_comptable')" v-model="form.logiciel_comptable"></textarea>
                                        </div>
                                    </div>
                                </div>
                                
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label for="tiers_lang" class="col-form-label">{{ $t("tiers.lang") }}</label>
                                            <LocaleInput id="tiers_lang" v-model="form.tiers_lang" />
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="text-center">
                                <b-button variant="primary" @click.prevent="checkForm"><font-awesome-icon v-if="processing" :icon="['fas', 'spinner']" pulse /> {{ $t("global.ajouter") }} <font-awesome-icon :icon="['fal', 'user-plus']"/></b-button>
                            </div>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>

<script type="text/javascript">
	import Vue from 'vue'
	
    import Contact from '@/mixins/Contact.js'
    import Fonction from '@/mixins/Fonction.js'
	import Navigation from "@/mixins/Navigation.js"
    import Phone from '@/mixins/Phone.js'
    import Shutter from "@/mixins/Shutter.js"
	import Tools from "@/mixins/Tools.js"
	import Tiers from '@/mixins/Tiers.js'
    import Countries from '@/assets/lang/countries/countries'
    import cloneDeep from 'lodash/cloneDeep'
    import Common from '@/assets/js/common.js'
    import _debounce from 'lodash/debounce'

	export default {
		name: "TiersForm",
		props: ['accountingplan_id', 'from', 'tiers', 'callback', 'code_onboarding'],
		mixins: [Contact, Fonction, Navigation, Phone, Shutter, Tools, Tiers],
		data () {
			return {
				ajouter: false,
				processing: false,
				error: [],
                form_message: "",
                tier_already_exists: false,
				form: {},
				countries: {},
				country_choosen: false,
                civilities: Common.getAllCivilities(),
                pappers_key: false,
                pappers_result: [],
                loading_pappers: false,
                debouncedSearch: _debounce(this.search, 300),
                searchValue: '',
                mapbox: null,
				default_form: {
					tiers_rs: '',
					tiers_firstname: '',
					tiers_lastname: '',
					tiers_civility: this.getTrad('civility.m'),
					tiers_mail: '',
					tiers_alias: '',
					tiers_address1: '',
					tiers_address2: '',
					tiers_postalcode: '',
					tiers_town: '',
					tiers_country: '75',
					tiers_website: '',
					tiers_entity: false,
					tiers_moral: true,
					tiers_currency: 'EUR',
					tiers_lang: Vue.i18n.locale(),
                    logiciel_comptable: '',
					phones: [
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.fixe'),
							phone_country: "FRA",
                            phone_libelle: ""
						},
						{
							phone_indicatif: '+33',
							phone_numero: '',
							phone_type: this.getTrad('tiers.phone_type.portable'),
							phone_country: "FRA",
                            phone_libelle: ""
						}
					]
				}
			}
		},
		mounted() {
			this.init_component()
		},
		methods: {
			async init_component() {
                const config = this.getConfig('api_config')
                const pappers = config.find(conf => conf.apiconfig_type == "PAP")
                if(pappers) {
                    this.pappers_key = pappers.apiconfig_key
                }

                this.mapbox = this.getMapBoxKey()

				this.addOrEdit()
			},
			async addOrEdit() {
				this.form_message = ""
				this.tier_already_exists = false

				this.countries = Countries[Vue.i18n.locale()].label

                this.form = this.default_form
                this.ajouter = true
			},
			checkForm: async function () {
				if(!this.processing)
				{
					this.processing = true

					this.form_message = ""
					this.error = []

                    if(this.form.tiers_rs != '') {
                        this.form.tiers_moral = true
                    }
                    else {
                        this.form.tiers_moral = false
                    }
                    
					if(this.form.tiers_moral == true) {
						if(!this.form.tiers_rs) { 
							this.error.push("Erreur rs") 
						}
					}
					else {
						if(!this.form.tiers_lastname){
							this.error.push("Erreur nom") 
						}
						if(!this.form.tiers_civility){
							this.error.push("Erreur civilité") 
						}
					}

					if(this.form.tiers_mail && !this.form.tiers_mail.match(this.regex_email)) {
						this.error.push('Erreur mail')
					}

					if(!this.form.tiers_country) {
						this.error.push("Erreur pays")
					}

					if(this.error.length == 0) {
                        this.form.tiers_civility = Common.manageCivilities(this.form.tiers_civility)

	                    const params = cloneDeep(this.form)
	                    let new_tiers_id = null
						try {
                            new_tiers_id = await this.addTiers(params)
                            await this.$sync.force(true)
                            new_tiers_id = this.$sync.replaceWithReplicated('tiers', new_tiers_id)
                            await this.saveTiersDescription(new_tiers_id, params.logiciel_comptable)

                            const params_contact = {
                                contact_lastname: params.tiers_lastname,
                                contact_firstname: params.tiers_firstname,
                                contact_civility: params.tiers_civility,
                                contact_mail: params.tiers_mail,
                                contact_address1: params.tiers_address1,
                                contact_address2: params.tiers_address2,
                                contact_postalcode: params.tiers_postalcode,
                                contact_town: params.tiers_town,
                                contact_country: params.tiers_country,
                                contact_operateur: 0,
                                contact_archive: 0,
                                contact_num_ordre: '',
                                phones: params.phones
                            }

                            const db = this.$storage.db
                            await db.transaction('rw', [db.contact, db.phone, db.tiers_contact, db.tiers_contact_fonction, db.fonction], async () => {
                                const contact_id = await this.addContact(params_contact)
                                const phones = params_contact.phones
                                if(phones) {
                                    for(let i = 0; i < phones.length; i++) {
                                        if(phones[i].phone_numero) {
                                            phones[i].contact_id = contact_id
                                            phones[i].tiers_id = null
                                            await this.addPhone(phones[i])
                                        }
                                    }
                                }

                                if(this.form.tiers_firstname && this.form.tiers_lastname) {
                                    const tierscontact_id = await this.addTiersContact(new_tiers_id, contact_id)
                                    const fonction_compta = await this.loadFonctionByCode('COMPTA')
                                    if(fonction_compta) {
                                        await this.addTiersContactFonction(fonction_compta[0].fonction_id, tierscontact_id)
                                    }
                                }
                            })

                            this.sendTiersComptaMail(new_tiers_id)
						}
						catch (e) {
							if (typeof(e) === 'string') {
								this.form_message = e
								this.failureToast(e)
							}
							else {
								console.error(e)
							}

                            this.processing = false
							return null
						}

                        this.processing = false

                        if(this.code_onboarding) {
                            const params =  {
                                code: this.code_onboarding,
                                done: 1,
                                skip: 0
                            }

                            this.ok(params)
                            this.shutterPanel().close(this.code_onboarding)
                        }
                        
                        this.$router.push({ name: 'tiersFiche', params: { tiers_id: new_tiers_id }})
					} else {
						this.processing = false
						this.form_message = "formulaire.erreur_champs_non_remplis"
                        window.scrollTo(0, 0) 
					}
				}
            },

            /* Vérifie si un tiers de la même raison sociale (ou de la même combinaison nom/prenom si pas personne morale) existe déjà */
            async checkIdenticalTierRs() {
				this.tier_already_exists = false

				const rs = this.form.tiers_moral === true ? this.form.tiers_rs : `${this.form.tiers_lastname} ${this.form.tiers_firstname}`
				if(!rs) {
					return false
				}

				const count = await this.tierExists(rs, this.form.tiers_id)
				this.tier_already_exists = count > 0
            },

			addPhoneNumber() {
				this.form.phones.push({
					phone_indicatif: '+33',
					phone_numero: '',
					phone_type: this.getTrad('tiers.phone_type.fixe'),
					phone_country: 'FRA',
                    phone_libelle: ''
				})
			},
			
			resetTown() {
				this.form.tiers_town = ''
			},

			resetPostalcode() {
				this.form.tiers_postalcode = ''
			},

            onSearch(search_val) {
                this.debouncedSearch(search_val)
            },

            async search(search_val) {
                if(!search_val) {
                    return
                }

                let type = "RS"
                this.loading_pappers = true

                if (!isNaN(search_val)){
                    if(search_val.length == 9){
                        type = "SIREN"
                    }else if(search_val.length == 14){
                        type = "SIRET"
                    }
                }

                const pappers = this.searchAPIPappers(search_val, type, false, false)
                    .then(res => {
                        if(res.resultats) {

                            this.pappers_result = res.resultats.map(tiers => {
                                return {
                                    tiers_rs: tiers.nom_entreprise,
                                    tiers_postalcode: tiers.siege.code_postal,
                                    tiers_address1: tiers.siege.adresse_ligne_1,
                                    tiers_address2: tiers.siege.adresse_ligne_2,
                                    tiers_town: tiers.siege.ville,
                                    tiers_siret: tiers.siege.siret,
                                    tiers_statut_rcs: tiers.statut_rcs
                                }
                            })
                        }
                    })

                // Ajout des résultats au e-select
                await Promise.all([pappers])

                this.loading_pappers = false
            },
            async searchPappers(option) {
                this.form.tiers_rs = option.tiers_rs
                this.form.tiers_address2 = option.tiers_address2
                this.form.tiers_address1 = option.tiers_address1
                this.form.tiers_town = option.tiers_town
                this.form.tiers_postalcode = option.tiers_postalcode
                this.form.tiers_siret = option.tiers_siret
                this.form.tiers_vat_number = vat
                this.form.tiers_statut_rcs = option.tiers_statut_rcs
                const vat = option.tiers_siret ? await this.getVATFromPappers(option.tiers_siret) : null
            }
        },
		computed : {
			isOnline() {
				return window.navigator.onLine
			}
		},
		components: {
			LoadingSpinner : () => import('GroomyRoot/components/Logos/LoadingSpinner_35'),
			ErrorAlert : () => import('GroomyRoot/components/Alert/ErrorAlert'),
			PhoneInput : () => import('GroomyRoot/components/Inputs/PhoneInput'),
            CountryInput : () => import('GroomyRoot/components/Inputs/CountryInput'),
            CurrencyInput : () => import('GroomyRoot/components/Inputs/CurrencyInput'),
            LocaleInput : () => import('@/components/Inputs/LocaleInput'),
            WarnAlert : () => import('GroomyRoot/components/Alert/WarnAlert'),
            EAddress : () => import('GroomyRoot/components/Utils/EAddress'),
			SearchInputCommune : () => import('@/components/Inputs/SearchInputCommune')
		}
	}
</script>
